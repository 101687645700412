<template lang="pug">
	div
		.mainbox
			v-container.main-container
				v-row(style='display: flex; justify-content: center; max-width: 300px')
					v-col.content-container(
						cols='12',
						lg='12',
						md='12',
						sm='12',
						xs='6',
						style='display: flex; flex-direction: column; justify-content: center; padding: 0'
					)
						.logo-container(
							style='width: 100%; display: flex; flex-direction: column; justify-content: center'
						)
							img(src='@/assets/img/radar-ooh.svg', alt='RADAR-OOH', style='width: 100%')
						.input-container
							input.input(
								placeholder='Логин',
								type='text',
								@keyup.enter='Enter',
								v-model='LoginData.login'
							)
							input.input(
								type='password',
								@keyup.enter='Enter',
								placeholder='Пароль',
								v-model='LoginData.password'
							)
						v-btn(
							block,
							tile,
							@keyup.enter='Enter',
							v-on:click='Enter',
							:loading='isLoading',
							style='background-color: rgba(72, 92, 170, 1)'
						) Войти
</template>

<script>
import Cookies from 'js-cookie';
import {LOGIN} from '@/store/const/auth'
import {mapActions, mapGetters} from 'vuex'

export default {
	data: function () {
		return {
			LoginData: {
				login: '',
				password: '',
			},
			Error: ''
		};
	},
	
	created() {
		if (this.$route.query['sessionIsDead']) {
			if (this.$notify) {
				this.$notify({
					type: 'warning',
					title: 'Сессия долго не обновлялась',
					text: 'Сессия закончилась, перезайдите снова',
				});
			}
		}
		if (this.$route.query['sessionIsNone']) {
			if (this.$notify) {
				this.$notify({
					type: 'warning',
					title: 'Ошибка',
					text: 'Необходимо войти в систему',
				});
			}
		}
	},
	computed: {
		...mapGetters(['loading']),
		isLoading() {
			return this.loading(LOGIN) === 'loading'
		},
		isError() {
			return this.loading(LOGIN) === 'error'
		}
	},
	methods: {
		...mapActions('Auth', {
			AuthLogin: LOGIN
		}),
		clearCookie() {
			let cookies = document.cookie.split(';');
			
			for (let i = 0; i < cookies.length; i++) {
				let cookie = cookies[i];
				let eqPos = cookie.indexOf('=');
				let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
			}
		},
		async Enter() {
			try {
				await this.AuthLogin(this.LoginData)
				if (!this.isError) {
					localStorage.removeItem('productolog');
					sessionStorage.removeItem('permissions');
					this.clearCookie();
					Cookies.remove('account/session');
					Cookies.remove('agency/session');
					Cookies.remove('root/session');
					await this.$router.push('/');
				} else {
					this.$notify({
						type: 'error',
						title: 'Ошибка входа',
						text: 'Введите правильный логин и пароль'
					});
				}
			} catch (e) {
				this.$notify({
					type: 'error',
					title: 'Ошибка входа',
					text: 'Ошибка на стороне сервера'
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
html {
	overflow-y: auto !important;
}

.mainbox {
	width: 100vw;
	height: 100vh;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background: url('~@/assets/img/background/lefttopback.svg'), url('~@/assets/img/background/rightbottomback.svg'),
	linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 0%), url('~@/assets/img/background/bg.jpg');
	background-size: 30%, 30%, cover, cover;
	background-color: var(--v-secondary-base);
	background-position: 0 0, 100% 100%, 0 0, 0 0;
	background-repeat: no-repeat;
	
	.main-container {
		align-self: center;
		display: flex;
		justify-content: center;
		
		.input-container {
			margin-top: 60px;
			
			.input {
				margin-bottom: 10px;
				width: 100%;
				height: 35px;
				padding: 8px 0 8px 13px;
				display: block;
				background-color: rgba(204, 204, 204, 0.5);
				color: var(--v-fontcolor-base);
				font-style: normal;
				font-weight: 300;
				font-size: 20px;
				line-height: 18px;
				border-radius: 4px;
			}
			
			.input::placeholder {
				color: var(--v-fontcolor-base);
				font-size: 15px;
			}
		}
		
		.v-btn {
			width: 100%;
			background-color: rgba(72, 92, 170, 1);
			color: var(--v-fontcolor-base);
			margin-top: 15px;
			border: none;
			border-radius: 4px;
			font-size: 15px;
			line-height: 18px;
		}
	}
}

@media (max-width: 375px) {
	body {
		background-size: 70%, 70%, cover, cover;
	}
}

@media (min-width: 375px) and (max-width: 599px) {
	body {
		background-size: 60%, 60%, cover, cover;
	}
}

@media (min-width: 600px) and (max-width: 1064px) {
	body {
		background-size: 45%, 45%, cover, cover;
	}
}

@media (min-width: 1065px) and (max-width: 1263px) {
	body {
		background-size: 40%, 40%, cover, cover;
	}
}
</style>
